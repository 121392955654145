import logo from "./logo.webp"

const  clientData = {
   client_entity: 40,
   attorney_firm: 'hoglundlaw.com',
    attorney_name: 'Robert Hoglund',
    attorney_number: '612-791-1382',
    attorney_email: 'ERTC@hoglundlaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_hoglund_law+(720p).mp4',
    mainColor: '#D3BC90',
    secondaryColor: '#021E42',
    siteLink: 'https://hoglundlaw.com',
    logo
}

export default clientData